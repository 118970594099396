import * as React from 'react'
import Layout from '../components/layout';

class RoomDetail extends React.Component {
    componentDidMount() {
        const script = document.createElement("script");    
        script.async = true;    
        script.src = "/gallery.js";             
        document.body.appendChild(script);

        const setmoreScript = document.createElement("script");
        setmoreScript.async = true;
        setmoreScript.id = "setmore_script";
        setmoreScript.src= "https://my.setmore.com/js/iframe/setmore_iframe.js";
        document.body.appendChild(setmoreScript)

    }
    render() { 
        console.log("your context is", this.props);
        var roomData = this.props.pageContext.roomData;
        return (
        <Layout titleSmall="ontdek de" titleLarge={roomData.name}>
            <section className="featured-area">
                <div className="container"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="galleria" style={{height: "30rem"}}>
                                {roomData.images.map((image, index) => {
                                    return <img key={index} alt={"Afbeelding van " + roomData.name} src={"/images/" + roomData.slug + "/" + image} />
                                })}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="single-feature d-flex flex-wrap justify-content-between">
                                <div className="icon d-flex align-items-center justify-content-center">
                                    <span className="lnr lnr-cog"></span>
                                </div>
                                <div className="desc">
                                    <h6 className="title text-uppercase">Eigenschappen</h6>
                                    {roomData.description}
                                    <ul className="fa-ul">
                                        {roomData.features.map((feature, index) => (
                                            <li key={index}><i className={"fa-li fa " + feature.icon}></i>{feature.text}</li>
                                        ))}
                                        <li><i className="fa-li fa fa-eur"></i>€4/pp Koffie,thee, water per dagdeel of dag</li>
                                        <li><i className="fa-li fa fa-eur"></i>€2/pp Fruit</li>
                                    </ul>
                                    <p className="font-italic">* Een dagdeel bestaat uit een periode van 4u.</p>
                                    <p className="font-italic">* Een dag bestaat uit een periode van 8u.</p>
                                    <p className="font-italic">* Voor boekingen buiten de vooropgestelde agenda kunt u steeds contact opnemen via <a href="mailto:info@pand99.be">info@pand99.be</a></p>

                                   <a id="Setmore_button_iframe" style={{float:"none"}} href="https://my.setmore.com/bookingpage/aa817d47-64cd-4d52-af61-0491a2625234"><img border="none" src="https://my.setmore.com/images/bookappt/SetMore-book-button.png" alt="Book an appointment with Pand 99 using SetMore" /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="about-generic-area">
                <div className="container border-top-generic">
                    <div className="galleria" style={{height: "40rem"}}>
                        {roomData.images.map((image, index) => {
                            return <img alt={"Afbeelding van " + roomData.name} key={image} src={"/images/" + roomData.slug + "/" + image} />
                        })}
                    </div>
                </div>
            </section>
        </Layout>
        )
    }
}
   
export default RoomDetail